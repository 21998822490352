import { PAGE_SIZE } from "@/utils/constants";
import axios from "axios";
import PaginationData from "./../../../general/PaginationData";
// import CustodyExpenseImg from "@/assets/images/CustodyExpenses.svg";
import CustodyExpensesFilter from "./CustodyExpensesFilter";

export default class CustodyExpense {
  // defaultImg = CustodyExpenseImg;
  constructor() {
    this.setInitialValue();
  }
  setInitialValue() {
    this.custodyExpenseToken = "";
    this.employeeToken = "";
    this.employeeNameCurrent = "";
    this.custodyExpenseNameCurrent = "";
    this.custodyExpenseNameEn = "";
    this.custodyExpenseNameAr = "";
    this.custodyExpenseNameUnd = "";
    this.custodyExpenseDescriptionCurrent = "";
    this.custodyExpenseDescriptionEn = "";
    this.custodyExpenseDescriptionAr = "";
    this.custodyExpenseDescriptionUnd = "";
    this.custodyExpenseNotes = "";
    this.custodyExpenseArchiveStatus = "";
  }
  fillData(data) {
    this.custodyExpenseToken = data.custodyExpenseToken;
    this.employeeToken = data.employeeToken;
    this.employeeNameCurrent = data.employeeNameCurrent;
    this.custodyExpenseNameCurrent = data.custodyExpenseNameCurrent;
    this.custodyExpenseNameEn = data.custodyExpenseNameEn;
    this.custodyExpenseNameAr = data.custodyExpenseNameAr;
    this.custodyExpenseNameUnd = data.custodyExpenseNameUnd;
    this.custodyExpenseDescriptionCurrent =
      data.custodyExpenseDescriptionCurrent;
    this.custodyExpenseDescriptionEn = data.custodyExpenseDescriptionEn;
    this.custodyExpenseDescriptionAr = data.custodyExpenseDescriptionAr;
    this.custodyExpenseDescriptionUnd = data.custodyExpenseDescriptionUnd;
    this.custodyExpenseNotes = data.custodyExpenseNotes;
    this.custodyExpenseArchiveStatus = data.custodyExpenseArchiveStatus;
  }

  async getAllCustodyExpenses(
    language,
    userAuthorizeToken,
    pagination = new PaginationData(),
    filterData = new CustodyExpensesFilter()
  ) {
    try {
      let response = await axios.get(
        `/CustodyExpenses/GetAllCustodyExpenses?language=${language}&userAuthorizeToken=${userAuthorizeToken}&cityArchiveStatus=false&page=${pagination.selfPage}&paginationStatus=true&pageSize=${PAGE_SIZE}&filterStatus=true&employeeToken=${filterData.employeeToken}&textSearch=${filterData.textSearch}`
      );
      return response;
    } catch (error) {
      return this.$t("errorCatch");
    }
  }

  async getDialogOfCustodyExpenses(
    language,
    userAuthorizeToken,
    filterData = new CustodyExpensesFilter()
  ) {
    return await axios.get(
      `/CustodyExpenses/GetDialogOfCustodyExpenses?language=${language}&userAuthorizeToken=${userAuthorizeToken}&filterStatus=true&employeeToken=${filterData.employeeToken}&textSearch=${filterData.textSearch}`
    );
  }

  async getCustodyExpenseDetails(language, userAuthorizeToken) {
    try {
      let response = await axios.get(
        `/CustodyExpenses/GetCustodyExpenseDetails?language=${language}&userAuthorizeToken=${userAuthorizeToken}&custodyExpenseToken=${this.custodyExpenseToken}`
      );
      return response;
    } catch (error) {
      return this.$t("errorCatch");
    }
  }

  async addOrUpdateCustodyExpense(language, userAuthorizeToken) {
    var data = {
      language: language,
      userAuthorizeToken: userAuthorizeToken,
      custodyExpenseToken: this.custodyExpenseToken,
      employeeToken: this.employeeToken,
      custodyExpenseNameEn: this.custodyExpenseNameEn,
      custodyExpenseNameAr: this.custodyExpenseNameAr,
      custodyExpenseNameUnd: this.custodyExpenseNameUnd,
      custodyExpenseDescriptionEn: this.custodyExpenseDescriptionEn,
      custodyExpenseDescriptionAr: this.custodyExpenseDescriptionAr,
      custodyExpenseDescriptionUnd: this.custodyExpenseDescriptionUnd,
      custodyExpenseNotes: this.custodyExpenseNotes,
    };

    try {
      if (
        this.custodyExpenseToken == "" ||
        this.custodyExpenseToken == undefined
      ) {
        return await axios.post(`/CustodyExpenses/AddCustodyExpense`, data);
      } else {
        return await axios.post(`/CustodyExpenses/UpdateCustodyExpense`, data);
      }
    } catch (e) {
      return this.$t("errorCatch");
    }
  }

  async archiveCustodyExpense(language, userAuthorizeToken) {
    var data = {
      language: language,
      userAuthorizeToken: userAuthorizeToken,
      custodyExpenseToken: this.custodyExpenseToken,
    };

    try {
      return await axios.post(`/CustodyExpenses/ArchiveCustodyExpense`, data);
    } catch (e) {
      return this.$t("errorCatch");
    }
  }
}
