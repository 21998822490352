export default class CustodyExpensesFilter {
  constructor() {
    this.setInitialValue();
  }
  setInitialValue() {
    this.employeeToken = "";
    this.textSearch = "";
  }
  fillData(data) {
    this.employeeToken = data.employeeToken;
    this.textSearch = data.textSearch;
  }
}
